<template>
  <div>
    <alert type="danger" class="casa-villa-warning" dismissible>
      <div class="alert-icon">
        <i class="now-ui-icons ui-1_bell-53"></i>
      </div>
      <strong>Caution:</strong> We have confirmed reports that this host has
      <strong>hiked</strong> the agreed price & provided 24 hours notice to
      customers
    </alert>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/header.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <div class="rating">
            <Octicon icon="star-fill" :width="24" /><Octicon
              icon="star"
              :width="24"
            /><Octicon icon="star" :width="24" /><Octicon
              icon="star"
              :width="24"
            /><Octicon icon="star" :width="24" /> (1)
          </div>
          <!-- <img class="n-logo" src="img/now-logo.png" alt="" /> -->
          <h1 class="h1-seo">Casa Villa</h1>
          <h2>Xenia Paliouri, Paliouri, Greece</h2>
          <n-button
            type="primary"
            round
            class="view-reviews"
            @click="viewReviews()"
            size="lg"
          >
            View Reviews
          </n-button>
        </div>
        <div class="category category-absolute">
          <!-- <n-button type="primary" @click="viewReviews()" size="lg">
                <Octicon icon="star" :width="12" /> View Reviews
            </n-button> -->
          <n-button type="success" @click="viewAlternatives()" size="lg">
            Recommended Alternatives
          </n-button>
        </div>
        <!-- <h6 class="category category-absolute">
          Designed by
          <a href="http://invisionapp.com/" target="_blank">
            <img src="img/invision-white-slim.png" class="invision-logo" /> </a
          >. Coded by
          <a href="https://www.creative-tim.com" target="_blank">
            <img
              src="img/creative-tim-white-slim2.png"
              class="creative-tim-logo"
            /> </a
          >.
        </h6> -->
      </div>
    </div>
    <div class="main">
      <div class="section section-about-us">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">About Casa Villa</h3>
              <p>
                Casa Villa, Xenia Paliouri is a 6-bedroom villa, located 3.1 km
                from Xenia Golden Beach. This venue has a mini-kitchen provided
                with amenities, such as refrigerators and kitchenware for
                self-catering.
              </p>
            </div>
          </div>
          <div class="separator separator-primary"></div>
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/place-photos/outside.jpg')"
                >
                  <!-- First image on the left side -->
                  <p class="blockquote blockquote-primary">
                    The centre of Paliouri is approximately 2km from the villa.
                    Guests can also get to Paralia Chroussou within a 15-minute
                    walk.
                    <br />
                    <strong>Check-in:</strong> from 15:00 until 23:59
                    <br />
                    <strong>Check-out:</strong> until 11:00
                  </p>
                </div>
                <!-- Second image on the left side of the article -->
                <div
                  class="image-container"
                  style="background-image: url('img/place-photos/room.jpg')"
                ></div>
              </div>
              <div class="col-md-5">
                <!-- First image on the right side, above the article -->
                <div
                  class="image-container image-right"
                  style="background-image: url('img/place-photos/by-pool.jpg')"
                ></div>
                <!-- <h3>What features does this property have?</h3> -->
                <p>
                  The venue is able to accommodate up to 12 guests + 2 babies,
                  comprising a total of 6 bedrooms and 6 bathrooms.
                </p>
                <p>
                  Thessaloniki airport is placed within 105km distance from the
                  property, and is reachable via shuttlebus or hire car.
                </p>
                <p>During your stay, you can indulge in hiking and fishing.</p>
                <p><strong>Check-in:</strong> from 15:00 until 23:59</p>
                <p><strong>Check-out:</strong> until 11:00</p>
                <p><strong>Licence number:</strong> 1070132</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <p class="blockquote blockquote-danger">
                <strong>Important</strong><br />
                This property will not accommodate hen, stag or similar parties.
                Please inform Casa Villa, Xenia Paliouri in advance of your
                expected arrival time. You can use the Special Requests box when
                booking, or contact the property directly with the contact
                details provided in your confirmation. In response to
                Coronavirus (COVID-19), additional safety and sanitation
                measures are in effect at this property. It is not possible to
                stay at this property for Coronavirus (COVID-19) quarantine
                purposes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-reviews">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="text-center">Reviews</h2>
            <div class="rating text-center">
              <Octicon icon="star-fill" :width="24" /><Octicon
                icon="star"
                :width="24"
              /><Octicon icon="star" :width="24" /><Octicon
                icon="star"
                :width="24"
              /><Octicon icon="star" :width="24" /> (1)
            </div>
            <card>
              <h4 class="title">AVOID!</h4>
              <div class="rating">
                <Octicon icon="star-fill" :width="20" /><Octicon
                  icon="star"
                  :width="20"
                /><Octicon icon="star" :width="20" /><Octicon
                  icon="star"
                  :width="20"
                /><Octicon icon="star" :width="20" /> (1)
              </div>
              <span
                >Written by Mr M. on
                <time datetime="2022-03-31"
                  >Thursday 31st March 2022</time
                ></span
              >
              <p>
                What can only be described as extortion. We had booked our stay
                at Casa Villa via Booking.com 10 months ago and paid the
                balance, only to receive an email from Booking.com stating that
                the host told them there had been a mistake in the pricing
                applied to our stay and that they wanted almost twice as much or
                they would cancel our reservation, giving us 24 hours to
                confirm. Thanks to this, we stand to lose hundreds of pounds due
                to conversion rate changes, and due to having to organise a
                different holiday, paying rearrangement fees for our flights,
                etc. This sort of thing isn't covered by insurance, but you
                never expect it to happen! It's against Booking.com's terms and
                conditions, and we told the hosts as much, but they offered a
                measly 200EUR off the doubled price. Save yourself the bother and avoid.
              </p>
            </card>
          </div>
        </div>
      </div>
      <div class="section section-alternatives">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
                <h2 class="text-center">Alternatives</h2>
                <p class="text-center">Coming Soon!</p>
            </div>
          </div>
      </div>
      <!-- <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/hero-image-1.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/hero-image-2.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/hero-image-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <basic-elements></basic-elements>
    <navigation></navigation>
    <tabs-section></tabs-section>
    <progress-pagination></progress-pagination>
    <notifications></notifications>
    <typography></typography>
    <javascript-components></javascript-components>
    <carousel-section></carousel-section>
    <nucleo-icons-section></nucleo-icons-section>
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Completed with examples</h2>
            <h5 class="description">
              The kit comes with three pre-built pages to help you get started
              faster. You can change the text and images and you're good to go.
              More importantly, looking at them will give you a picture of what
              you can built with this powerful Bootstrap 4 ui kit.
            </h5>
          </div>
        </div>
      </div>
    </div>
    <signup-form></signup-form>
    <examples-section></examples-section>
    <download-section></download-section> -->
  </div>
</template>
<script>
import { Button } from "@/components";
import { Card } from "@/components";
import { Alert } from "@/components";
import Octicon from "@/components/Octicon.vue";
import { Parallax } from "@/components";
// import BasicElements from "./components/BasicElementsSection";
// import Navigation from "./components/Navigation";
// import TabsSection from "./components/Tabs";
// import ProgressPagination from "./components/ProgressPagination";
// import Notifications from "./components/Notifications";
// import Typography from "./components/Typography";
// import JavascriptComponents from "./components/JavascriptComponents";
// import CarouselSection from "./components/CarouselSection";
// import NucleoIconsSection from "./components/NucleoIconsSection";
// import SignupForm from "./components/SignupForm";
// import ExamplesSection from "./components/ExamplesSection";
// import DownloadSection from "./components/DownloadSection";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Card,
    [Button.name]: Button,
    Alert,
    Octicon,
    Parallax,
    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    // CarouselSection,
    // NucleoIconsSection,
    // SignupForm,
    // ExamplesSection,
    // DownloadSection,
  },
  methods: {
    viewReviews: function () {
      let reviewsPosition = document
        .querySelector(".section-reviews")
        .getBoundingClientRect();
      window.scrollTo(reviewsPosition.left, reviewsPosition.top - 100);
    },
    viewAlternatives: function () {
      let alternativesPosition = document
        .querySelector(".section-alternatives")
        .getBoundingClientRect();
      window.scrollTo(
        alternativesPosition.left,
        alternativesPosition.top - 100
      );
    },
  },
};
</script>
<style lang="scss">
.octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
  overflow: visible;
}
.btn-primary.btn-round.btn-simple.view-reviews {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  vertical-align: middle;
}
.brand {
  .rating {
    .octicon {
      color: yellow;
    }
    font-size: 18px;
    line-height: 24px;
    vertical-align: middle;
    margin-bottom: 1rem;
  }
}
.alert.casa-villa-warning {
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.section.section-reviews {
    background: #f9f9f9;
}
.section.section-reviews, .section.section-alternatives {
  h2 {
    text-transform: uppercase;
    margin-bottom: 0.2rem;
  }
  h4 {
    margin-top: 0;
    padding-top: 0;
  }
  p {
    margin-top: 1rem;
  }
  .rating {
    font-size: 16px;
    line-height: 16px;
    vertical-align: middle;
    margin-bottom: 1rem;
    &.text-center {
      margin-bottom: 3rem;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
