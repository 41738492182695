<template>
    <span :class="wrapperClasses" v-html="octicons[icon].toSVG(svgArgs)"></span>
</template>
<script>
    import octicons from '@primer/octicons';

    export default {
        name: 'octicon',
        props: {
            icon: String,
            width: Number,
            additionalClasses: String,
            ariaLabel: String
        },
        data() {
            return {
                octicons: octicons
            }
        },
        computed: {
            svgArgs() {
                let svgArgs = {};
                if (this.ariaLabel) {
                    svgArgs['aria-label'] = this.ariaLabel;
                }
                if (this.width) {
                    svgArgs['width'] = this.width;
                }
                return svgArgs;
            },
            wrapperClasses() {
                return this.icon + (this.additionalClasses ? ' ' + this.additionalClasses : '');
            }
        }
    }
</script>